import React from "react";
import AdressHederModel from "./AdressHederModel";

export default function Header() {
  return (
    <div>
      <header className="header-wrapper w-100 posr bg-image-bottom-center header-border">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid posr">
            <a className="navbar-brand light-logo" href="#">
              <img
                src="assets/images/icon/aarma_bridge_dark.svg"
                alt="logo"
                width="200px"
                height="50px"
              />
            </a>
            <a className="navbar-brand dark-logo" href="#">
              <img
                src="assets/images/icon/aarma_bridge.svg"
                alt="logo"
                width="200px"
                height="50px"
              />
            </a>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <a
                href="#"
                className="btn btn-md menu-btn border-0 text-gray-900 rajdhani-700 rounded-6 bg-current px-4 font-sm text-uppercase"
              >
                {" "}
                Connect Wallet
              </a>
              <a href="/" className="ms-2 btn menu-btn btn-noti">
                <i class="bi bi-grid"></i>
              </a>
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#adesssmodal"
                className="ms-2 btn menu-btn btn-noti"
              >
                <i className="bi-bell font-md m-0" />
              </a>
            </div>
            <a href="/" className="ms-2 btn header-btn btn-noti">
              <i class="bi bi-grid"></i>
            </a>
            <a
              href=""
              data-bs-toggle="modal"
              data-bs-target="#adesssmodal"
              className="ms-2 btn header-btn btn-noti"
            >
              <i className="bi-bell font-md m-0" />
            </a>
        
            <a
              href="#"
              className="btn btn-md header-btn border-0 text-gray-900 rajdhani-700 ms-2 rounded-6 bg-current px-4 font-sm text-uppercase"
            >
              {" "}
              Connect Wallet
            </a> */}
          </div>
          <AdressHederModel />
        </nav>
      </header>
    </div>
  );
}
